<!--
服务订单
-->
<template>
  <div class="mainBox">
    <div class="shipTitle">
      <div class="changTitle">
        <div
          class="changeItme"
          :class="changeIndex == 1 ? 'changeItmeActive' : ''"
          @click="changeIndex = 1"
        >
          基础信息
        </div>
      </div>
    </div>
    <!---->
    <div class="shipMain">
      <div class="shipNav">
        <img
          src="@/assets/img/homeGuardianship/pressTitle.png"
          alt=""
          class="titleIcon"
        />
        <span class="titleName">基础信息</span>
      </div>

      <!-- 表单 -->
      <div class="progressFirst">
        <el-row :gutter="30" class="proRow">
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">姓名：</div>
              <div class="colValue">{{ userInfo.userName }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">性别：</div>
              <div class="colValue">{{ userInfo.sex == 0 ? "男" : "女" }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">出生日期</div>
              <div class="colValue">{{ userInfo.bornDate }}</div>
            </div>
          </el-col>

          <!--  -->
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">电话号码：</div>
              <div class="colValue">{{ userInfo.mobile }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">身份证号码：</div>
              <div class="colValue">{{ userInfo.cardNo }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">籍贯：</div>
              <div class="colValue">{{ userInfo.nativePlace }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">家庭电话：</div>
              <div class="colValue">{{ userInfo.homeTel }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">地址：</div>
              <div class="colValue">{{ userInfo.address }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">民族：</div>
              <div class="colValue">{{ userInfo.nationTxt }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">文化程度：</div>
              <div class="colValue">{{ userInfo.diploma }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">单位：</div>
              <div class="colValue">{{ userInfo.company }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">收入：</div>
              <div class="colValue">{{ userInfo.revenue }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">职业状态：</div>
              <div class="colValue">{{ userInfo.occupationStatusTxt }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">婚姻：</div>
              <div class="colValue">{{ userInfo.marriageTypeTxt }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">血型：</div>
              <div class="colValue">{{ userInfo.bloodTypeTxt }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">身高：</div>
              <div class="colValue">{{ userInfo.height }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">体重：</div>
              <div class="colValue">{{ userInfo.weight }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">BMI：</div>
              <div class="colValue">{{ userInfo.bmi }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">用户属性：</div>
              <div class="colValue">{{ userInfo.userPropertyTxt }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">能力等级：</div>
              <div class="colValue">{{ userInfo.abilityLevelTxt }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">健康状况：</div>
              <div class="colValue">{{ userInfo.healthStatusTxt }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">居住状态：</div>
              <div class="colValue">{{ userInfo.liveStatusTxt }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">客户类型：</div>
              <div class="colValue">{{ userInfo.userTypeTxt }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">备注：</div>
              <div class="colValue">{{ userInfo.remark }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { GetBizDoc } from "../../api/homeGuardianship";
export default {
  components: {},
  data() {
    return {
      timeData: "",
      changeIndex: 1,
      userInfo: {},
    };
  },
  created() {
    this.getBizDoc();
  },

  mounted: function () {},
  destroyed: function () {},
  computed: {},
  watch: {},
  methods: {
    getBizDoc() {
      GetBizDoc().then((res) => {
        console.log(res);
        let data = res.result;
        this.userInfo = data.userInfo;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.col-content {
  display: flex;
  align-items: center;
}
.progressFirst {
  margin-top: 20px;
}
.colLable {
  font-size: 12px;
  font-weight: 400;
  color: #2f69e6;
  flex: 1;
  text-align: right;
}
.colValue {
  width: 292px;
  min-height: 38px;
  background: rgba(47, 105, 230, 0.05);
  border-radius: 5px 5px 5px 5px;
  padding: 13px 15px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.proRow {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  .el-col {
    margin: 10px 0;
  }
}
</style>
